import React, {useState} from 'react';

import { graphql } from "gatsby"

import parseDate from '../utils/parseDate';
import "../theme/layout.scss"
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
import HeaderNew from '../components/layout/HeaderLayout';

import ModalSuscribe from '../components/layout/ModalSuscribre';


const pageSearch = ({location, data }) =>{

    const queryParams = new URLSearchParams(location.search);
    let q = queryParams.get('q');

    //validacion en caso de que q sea null o undefined
    if(q == "" || q == null || q == undefined){
        q = null;
    }

    const tecnico = data.tecnico.edges
    const mercadosALaApertura = data.mercadosALaApertura.edges
    const diarioEconomico = data.diarioEconomico.edges
    const estrategiaDeInversion = data.estrategiaDeInversion.edges
    const flashTecnico = data.flashTecnico.edges
    const flashEmisora = data.flashEmisora.edges
    const flashEconomico = data.flashEconomico.edges
    const flashInternacional = data.flashInternacional.edges
    const flashRentaFija = data.flashRentaFija.edges
    const carteraTecnico = data.carteraTecnico.edges
    const carteraModelo = data.carteraModelo.edges
    const expectativasReporteTrimestral = data.expectativasReporteTrimestral.edges
    const expectativasFinancierasAnuales = data.expectativasFinancierasAnuales.edges
    const inicioDeCobertura = data.inicioDeCobertura.edges
    const reporteTrimestral = data.reporteTrimestral.edges
    const semanarioFinanciero = data.semanarioFinanciero.edges
    const presentacionAnalisis = data.presentacionAnalisis.edges
    const generico = data.generico.edges
    const diarioInternacional = data.diarioInternacional.edges

    console.log(tecnico)
    //functions modal suscribe
    const [open, setOpen] = useState(false);
    function openSuscribe(){
      setOpen(true);
    }
    function closeSuscribe(){
      setOpen(false);
    }


    return (
      <>
            <HeaderNew openModal={openSuscribe} ticker={false} mb={true}/>
            <div className='wrap-content'>
              <h2>Resultados encontrados para <em>"{q}"</em></h2>

              {diarioInternacional.map(({ node }) => {
                  const { titulo, resumen, slug, createdAt, autor} = node
                  if( q== "diario internacional" || q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ) {
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/internacional/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {tecnico.map(({ node }) => {
                  const { titulo, resumen, slug, createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ) {
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/tecnico/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {mercadosALaApertura.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/mercados-a-la-apertura/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {diarioEconomico.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  // console.log(node)
                  // console.log(renderRichText(resumen))
                  if(  q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/economico/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                              {/* {renderRichText(resumen)} */}
                          </div>
                      )
                  } else { return false }
                
              })}

              {estrategiaDeInversion.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/estrategia-de-inversion/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {flashTecnico.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/tecnico/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}
              {flashEmisora.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt,autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/renta-variable/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}


              {flashEconomico.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/economico/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {flashInternacional.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/internacional/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {flashRentaFija.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/renta-fija/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}


              {carteraTecnico.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/tecnico/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {carteraModelo.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/renta-variable/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {expectativasReporteTrimestral.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/renta-variable/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {expectativasFinancierasAnuales.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt,autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/renta-variable/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {inicioDeCobertura.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/renta-variable/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}
              {inicioDeCobertura.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/renta-variable/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {reporteTrimestral.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt,autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/renta-variable/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {semanarioFinanciero.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/semanario-financiero/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {presentacionAnalisis.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt, autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/presentacion-analisis/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}

              {generico.map(({ node }) => {
                  const { titulo, resumen, slug,createdAt,autor} = node
                  if( q !== null && ( titulo.toLowerCase().includes( q.toLowerCase() ) || resumen != null && resumen.toLowerCase().includes( q.toLowerCase() ) ) ){
                      return (
                          
                          <div className="item">
                              <h2 className="title">{titulo}</h2>
                              <p className="resume-content">{resumen}</p>
                              <p className="date">
                                {parseDate(createdAt)}. {autor.nombre}
                              </p>
                              <a href={`/temas-del-momento/${slug}`} >
                                  <div className="link">Ver más</div>
                              </a>
                          </div>
                      )
                  } else { return false }
                
              })}
            </div>
            <CustomFooter openModal={openSuscribe}/>
            <GdprCookies />
            {
              open && <ModalSuscribe closeModal={closeSuscribe}/>
            }
      </>
    )
}

export default pageSearch

export const pageQuery = graphql`
query MyQuery{
  diarioInternacional: allContentfulDiarioInternacional {
    edges {
      node {
        slug
        titulo
        resumen
        createdAt
        autor {
          nombre
        }
      }
    }
  }
    tecnico: allContentfulPulsoTecnico {
      edges {
        node {
          slug
          titulo
          resumen
          createdAt
          autor {
            nombre
          }
        }
      }
    }
    mercadosALaApertura: allContentfulMercadosALaApertura {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      diarioEconomico: allContentfulDiarioEconomico {
        edges {
          node {
            slug
            titulo
            resumen{
              raw  
            }
            createdAt
            autor {
              nombre
            }
          }
        }
      }

      estrategiaDeInversion: allContentfulEstrategiaDeInversion {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }

      flashTecnico: allContentfulFlashTecnico {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      flashEmisora: allContentfulFlashEmisora {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      flashEconomico: allContentfulFlashEconomico {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      flashInternacional: allContentfulFlashInternacional {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      flashRentaFija: allContentfulFlashRentaFija {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      carteraTecnico: allContentfulCarteraTecnico {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      carteraModelo: allContentfulCarteraModelo {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      expectativasReporteTrimestral: allContentfulExpectativasReporteTrimestral {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      expectativasFinancierasAnuales: allContentfulExpectativasFinancierasAnuales {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      inicioDeCobertura: allContentfulInicioDeCobertura {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      reporteTrimestral: allContentfulReporteTrimestral {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      semanarioFinanciero: allContentfulSemanarioFinanciero {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      presentacionAnalisis: allContentfulPresentacionAnalisis {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }
      generico: allContentfulGenerico {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
              nombre
            }
          }
        }
      }

  }
`;
